<template>
  <div class="main noScroll">
    <div class="topbar">
      <!-- <Tabs v-model="currentTabIndex" class="tabs" key="parent">
        <TabPane v-for="item in tabArr" :key="item.name" :label="item.name"></TabPane>
      </Tabs> -->
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <Select v-model="area"
                    style="width:150px"
                    class="m-r-10">
              <Option v-for="item in areaArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <Input v-model="carNumber"
                   placeholder="机构名称"
                   class="m-r-10"
                   style="width:160px" />
            <DatePicker type="daterange"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        @on-change="onChangeDate"
                        style="width:260px"></DatePicker>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template>
        <div class="section">
          <div class="section-title">
            <span>运行情况</span>
            <span>
              <Icon custom="i-icon icon-tishi1"
                    size="16"
                    color="#FF9B77"></Icon>
              此页面统计数据，仅统计环卫人员，未统计用户
            </span>
          </div>
          <ul class="section-list">
            <li class="section-item"
                v-for="(item,index) in dimissionCaseArr"
                :key="index">
              <span class="fs-14">{{item.title}}</span>
              <span class="fs-48 m-l-8">{{item.value}}</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <Tabs v-model="currentTotalEchartsTabIndex"
                class="tabs"
                key="child7">
            <TabPane v-for="item in totalEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <Echarts height="400px"
                   :options="totalOptions"></Echarts>
        </div>
      </template>
      <div class="section">
        <template>
          <Tabs v-model="currentTotalTabIndex"
                class="tabs"
                key="child6">
            <TabPane v-for="item in totalTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
        </template>
        <div class="table">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="data"
                 border
                 stripe
                 :loading="tableLoading"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
            <!-- <template slot-scope="{ row }"
                      slot="operation">
              <Button @click.stop=""
                      type="text">处理</Button>
            </template> -->
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from '@/components/common/Echarts';
export default {
  components: {
    Echarts
  },
  data () {
    return {
      area: 0,
      areaArr: [{
        id: 0,
        name: '区域'
      },
      {
        id: 1,
        name: '项目'
      },
      {
        id: 2,
        name: '标段'
      },
      {
        id: 3,
        name: '部门'
      },
      {
        id: 4,
        name: '班组'
      },
      ],
      date: [],
      dimissionCaseArr: [{
        title: '离职人数',
        value: '0'
      },
      {
        title: '离职率',
        value: '0'
      },
      {
        title: '当前在职人数',
        value: '0'
      }
      ],
      currentTotalEchartsTabIndex: 0,
      totalEchartsTabArr: [{
        name: '离职情况分析'
      },],
      currentTotalTabIndex: 0,
      totalTabArr: [{
        name: '离职汇总'
      }, {
        name: '离职明细'
      }
      ],
      totalOptions: {
        xAxis: {
          type: 'category',
          data: ['05/07', '05/08', '05/09', '05/10', '05/11', '05/12', '05/13']
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [{
          type: 'line',
          smooth: true,
          data: [427, 156, 212, 205, 270, 210, 196],
        }],
        color: ['#25bb96'],
      },
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      data: [],
      selectArr: [],
      carNumber: '',
      columnsTotal: [{
        type: 'selection',
        width: 45,
        align: 'center'
      }, {
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '机构名称',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '责任人',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '机构类型',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '当前及购人数',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '离职人数',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '离职率',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '离职男性',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '离职女性',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      ],
      columnsDetail: [{
        type: 'selection',
        width: 45,
        align: 'center'
      }, {
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '姓名',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '所属机构',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '离职日期',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '离职原因',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '工号',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '工种',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '作业大类',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '工龄',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '性别',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      }
      ],

    };
  },
  computed: {
    getColumns () {
      let columns = []
      switch (this.currentTotalTabIndex) {
        case 0:
          columns = this.columnsTotal
          break;
        case 1:
          columns = this.columnsDetail
          break;
        default:
          break;
      }
      return columns
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.getList()
    },
    onChangeDate (date) {
      this.date = date
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {

    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";

.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  overflow: auto;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;

      .topbar-search-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .topbar-search-item-key {
          width: 70px;
          text-align: right;
          color: #a4a4a4;
        }
      }
    }
  }

  .content {
    .section {
      padding: 0px 15px 15px 15px;
      background-color: #fff;
      margin-top: 16px;

      .section-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid #ddd;

        span:first-child {
          font-weight: 700;
          font-size: 16px;
          padding-left: 10px;
          border-left: 2px solid #25bb96;
        }
      }

      .section-list {
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .section-item {
          span {
            font-weight: 700;
          }
        }
      }
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
